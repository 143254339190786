<script setup>
import { computed } from "vue";
import { useCustomizerStore } from "@/stores/customizer";

const customizer = useCustomizerStore();

//const dark = ref(false);
const dark = computed(() => {
  if (
    customizer.actTheme === "DARK_ORANGE_THEME"
  ) {
    return true;
  } else {
    return false;
  }
});
</script>
<template>
  <LayoutFullLogoLight v-if="dark" v-bind="$attrs" />
  <LayoutFullLogoDark v-else v-bind="$attrs" />
</template>
